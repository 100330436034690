import React from 'react'
import styles from './login.module.sass'

const CLIENT_ID = '714402863795-4erefe28dp5uclnbant1o3eokootn2e8.apps.googleusercontent.com'
const REDIRECT_URI = `${window.location.origin}/callback`

const Login = () => {
  const signIn = () => {
    const authUrl = 'https://accounts.google.com/o/oauth2/auth'
      + '?response_type=code'
      + `&client_id=${CLIENT_ID}`
      + `&redirect_uri=${REDIRECT_URI}`
      + '&scope=email%20profile'
    window.location.href = authUrl
  }

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.loginBox}>
          <h1>Yile AI</h1>
          <div className={styles.loginWarning}>
            目前iphone手機不支援問題，維修中。
          </div>
          <button
            className={styles.googleButton}
            type="button"
            onClick={signIn}
          >
            Google 登入
          </button>

        </div>
      </div>
    </div>
  )
}

export default React.memo(Login)
